
<template>
  <BackofficeBase :loader_prop="loader_prop">
    <div v-if="! show_ty">
      <div class="rtl-class">
        <form @submit="distribution_point_save">
          <div>
            <div>
              שם:
            </div>
            <div>
              <input v-model="name" type="text" v-bind:class="{input_error : name_input_error}" />
              <div class="input_error_txt" v-if="name_input_error">
                שגיאה בשם
              </div>
            </div>
          </div>
          <div>
            <div>
              כתובת:
            </div>
            <div>
              <input v-model="address" type="text" v-bind:class="{input_error : address_input_error}" />
              <div class="input_error_txt" v-if="address_input_error">
                שגיאה בכתובת
              </div>
            </div>
          </div>
          <div>
            <div>
              כתובת דוא"ל:
            </div>
            <div>
              <input v-model="email" type="text" v-bind:class="{input_error : email_input_error}" />
              <div class="input_error_txt" v-if="email_input_error">
                שגיאה בכתובת דוא"ל
              </div>
            </div>
          </div>
          <!-- <div v-if="distribution_point_id == '0'"> -->
            <div>
              סיסמא:
            </div>
            <div>
              <input v-model="password" type="password" v-bind:class="{input_error : password_input_error}" />
              <div class="input_error_txt" v-if="password_input_error">
                שגיאה בסיסמה
              </div>
            </div>
          <!-- </div> -->
          <div class="submit_cont">
            <input type="submit" value="שמירה" />
          </div>
        </form>
      </div>
    </div>
    <div v-if="show_ty">
      בוצע<br />
      <router-link to="/backoffice/distribution_points">חזרה</router-link>
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: 'DistributionPointEdit',
  components: {
    BackofficeBase
  },
  data() {
    return {
      loader_prop : false,
      view_data : [],
      show_ty : false,
      distribution_point_id : this.$route.query.id,
      email : '',
      name : '',
      address : '',
      password : '',
      name_input_error : false,
      address_input_error : false,
      password_input_error : false,
      email_input_error : false
    }

  },
  async mounted() {
    this.distribution_point_id = this.$route.query.id
    if (this.distribution_point_id != '0') {
      let backendModel = new BackendModel()
      let data = await backendModel.backendRequest("/Api/service/backoffice/backoffice_get_point_to_edit", {"id": this.distribution_point_id})
      this.email = data.data.data.email
      this.address = data.data.data.address
      this.name = data.data.data.name
      console.log(data.data)
    }
  },
  methods : {
    async distribution_point_save(e) {
      e.preventDefault()
      let errs = 0
      if (this.name == '') {
        this.name_input_error = true
        errs++
      }
      if (this.address == '') {
        this.address_input_error = true
        errs++
      }
      if (this.password == '') {
        this.password_input_error = true
        errs++
      }
      if (this.email == '') {
        this.email_input_error = true
        errs++
      }
      
      if (errs == 0) {
        this.loader_prop = true
        let backendModel = new BackendModel()
        let params = {
          id : this.distribution_point_id,
          name : this.name,
          address : this.address,
          password : this.password,
          email : this.email
        }
        await backendModel.backendRequest("/Api/service/backoffice/save_distribution_point", params)
        this.loader_prop = false
        this.show_ty = true
      }
    }
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>

